import { Component } from '@angular/core';
import { Platform, NavController, MenuController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { AuthService } from './services/auth.service';
import * as firebase from 'firebase/app';
import { environment } from "../environments/environment"


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    public navController: NavController,
    private platform: Platform,
    private storage: Storage,
    private authService: AuthService,
  ) {
    this.initializeApp();
  }


  async initializeApp() {
   
    this.platform.ready().then(() => {      
      this.storage.get('auth').then((val) => {
        if (val && val.Success) {
          if(val.Success){
            this.authService.APIKey = val.APIKey;

            this.authService.firstName = val.FirstName;
            this.authService.lastName = val.lastName;

            this.authService.userId = val.UserID;
            this.authService.clientId = val.ClientID;
            this.authService.employeeId = val.DriveEmployeeID;
            this.navController.navigateRoot('home');        
          }   
        } else {
          this.navController.navigateRoot('login');
        }
  
      })
    });
    //firebase.initializeApp(environment.firebase);

  }
}
