import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from './auth.service';
import { Storage } from '@ionic/storage';
import { Observable,of, throwError } from 'rxjs';
import { Md5 } from 'ts-md5/dist/md5';
import { DATE } from '../constant';

import { Platform, NavController, MenuController } from '@ionic/angular';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    public navController: NavController,
    private platform: Platform,
    public authService: AuthService,
     public router: Router,
     private storage: Storage,

     ) {}
  canActivate(): Promise<boolean> {
    return new Promise((resolve) => {
      this.storage.get('auth').then((val) => {
        if (val?.Success) {
        
            this.authService.APIKey = val.APIKey;
            this.authService.userRole = val.UserType; 
            this.authService.firstName = val.FirstName;
            this.authService.lastName =  val.LastName;
            this.authService.userId = val.UserID;
            this.authService.clientId = val.ClientID;   
            this.authService.employeeId = val.DriveEmployeeID;
            this.authService.signature = Md5.hashStr(val.APIKey+DATE);    
         
            return resolve(true);
        } else {
          return resolve(false);
        }
      })
      })
  }
  
}